import React from 'react';

function Resume() {

    return (
      <div className="Resume">
        <div>Resume</div>
        <div>Download full version of my Resume</div>
        <div>
            <h2>Experience</h2>
                <div>
                    <h3>The Associated Press</h3>
                    <div>Data Visualization Developer</div>
                    <div>August 2022 - Now</div>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                        lacus pulvinar. Aliquam erat volutpat. Duis vel viverra nulla. Pellentesque habitant morbi tristique senectus et netus et 
                        malesuada fames ac turpis egestas. Nam mollis ligula ipsum, vitae aliquam orci facilisis id. In tincidunt, ante ac blandit 
                        sodales, nulla turpis rhoncus orci, et commodo ligula turpis eget felis. Integer ut purus ornare felis tristique tempor. Mauris 
                        ultrices ornare quam in lacinia. Ut eget feugiat lectus. Sed bibendum, odio non tempus ultrices, nisi ligula 
                        consequat leo, ut tincidunt ligula nibh in libero. Nullam sed consequat felis. 
                    </div>
                </div>  
                <div>
                    <h3>Northeastern University</h3>
                    <div>Data Visualization Designer and Developer</div>
                    <div>September 2019 - August 2022</div>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                        lacus pulvinar. Aliquam erat volutpat. Duis vel viverra nulla. Pellentesque habitant morbi tristique senectus et netus et 
                        malesuada fames ac turpis egestas. Nam mollis ligula ipsum, vitae aliquam orci facilisis id. In tincidunt, ante ac blandit 
                        sodales, nulla turpis rhoncus orci, et commodo ligula turpis eget felis. Integer ut purus ornare felis tristique tempor. Mauris 
                        ultrices ornare quam in lacinia. Ut eget feugiat lectus. Sed bibendum, odio non tempus ultrices, nisi ligula 
                        consequat leo, ut tincidunt ligula nibh in libero. Nullam sed consequat felis. 
                    </div>
                </div> 
                <div>
                    <h3>Boston Globe</h3>
                    <div>Editorial Designer</div>
                    <div>January 2018 - November 2018</div>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                        lacus pulvinar. Aliquam erat volutpat. Duis vel viverra nulla. Pellentesque habitant morbi tristique senectus et netus et 
                        malesuada fames ac turpis egestas. Nam mollis ligula ipsum, vitae aliquam orci facilisis id. In tincidunt, ante ac blandit 
                        sodales, nulla turpis rhoncus orci, et commodo ligula turpis eget felis. Integer ut purus ornare felis tristique tempor. Mauris 
                        ultrices ornare quam in lacinia. Ut eget feugiat lectus. Sed bibendum, odio non tempus ultrices, nisi ligula 
                        consequat leo, ut tincidunt ligula nibh in libero. Nullam sed consequat felis. 
                    </div>
                </div> 
        </div>
        <div>
            <h2>Education</h2>
                <div>
                    <h3>Northeastern University</h3>
                    <div>Master of Science</div>
                    <div>Information Design and Data Visualization</div>
                    <div>May 2022</div>
                </div> 
                  <div>
                    <h3>Mount Holyoke College</h3>
                    <div>Bachelor of Arts</div>
                    <div>Interactive Design and Development</div>
                    <div>May 2016</div>
                </div> 
        </div>
        <div>
            <div>Toolkit</div>
                <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                lacus pulvinar. Aliquam erat volutpat. Duis vel viverra nulla. Pellentesque habitant morbi tristique senectus et netus et 
                malesuada fames ac turpis egestas. Nam mollis ligula ipsum, vitae aliquam orci facilisis id. In tincidunt, ante ac blandit 
                sodales, nulla turpis rhoncus orci, et commodo ligula turpis eget felis. Integer ut purus ornare felis tristique tempor.
                </div>
                 <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                lacus pulvinar. Aliquam erat volutpat. Duis vel viverra nulla. Pellentesque habitant morbi tristique senectus et netus et 
                malesuada fames ac turpis egestas. Nam mollis ligula ipsum, vitae aliquam orci facilisis id. In tincidunt, ante ac blandit 
                sodales, nulla turpis rhoncus orci, et commodo ligula turpis eget felis. Integer ut purus ornare felis tristique tempor.
                </div>
                 <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                lacus pulvinar. Aliquam erat volutpat. Duis vel viverra nulla. Pellentesque habitant morbi tristique senectus et netus et 
                malesuada fames ac turpis egestas. Nam mollis ligula ipsum, vitae aliquam orci facilisis id. In tincidunt, ante ac blandit 
                sodales, nulla turpis rhoncus orci, et commodo ligula turpis eget felis. Integer ut purus ornare felis tristique tempor.
                </div>
        </div>
         <div>
            <h2>Technical Skills</h2>
                <div>
                    <h3>Development</h3>
                    <div> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                          lacus pulvinar.
                     </div>
                </div> 
                  <div>
                    <h3>Database Management</h3>
                    <div> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                          lacus pulvinar.
                     </div>
                </div> 
                <div>
                    <h3>Data Analysis</h3>
                    <div> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                          lacus pulvinar.
                     </div>
                </div> 
                  <div>
                    <h3>UI/UX Design</h3>
                    <div> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                          lacus pulvinar.
                     </div>
                </div>
                 <div>
                    <h3>Version Control</h3>
                    <div> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                          lacus pulvinar.
                     </div>
                </div>
        </div>
             <div>
            <h2>Technical Skills</h2>
                <div>
                    <div> 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                        lacus pulvinar.
                     </div>
                </div> 
                  <div>
                    <div> 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                        lacus pulvinar.
                     </div>
                </div> 
                <div>
                    <div> 
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum felis ac augue sagittis, consequat ullamcorper 
                        lacus pulvinar.
                     </div>
                </div> 
             
        </div>
      </div>
    )

}

export default Resume;